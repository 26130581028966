<template>
    <div>
        <div class="tracking-wrapper">

            
            <div class="truckDetails">
                <div class="truck-driver-details">

                    <router-link to="/units/track">Overview</router-link>

                    <div v-if="details" class="details">

                        <h2>{{ details.name }}</h2>

                        <div class="detailsItem" v-if="details.driver">
                            <div><i class="fa-regular fa-id-card"></i></div>
                            <div>
                                <div>{{ details.driver.name }} {{ details.driver.lastname ? details.driver.lastname : '' }}</div>
                                <div class="time" v-if="details.driver.phone">{{ details.driver.phone }}</div>
                            </div>
                        </div>


                        <div class="detailsItem" v-if="stats.gps">
                            <div><i class="fa-solid fa-location-dot"></i></div>
                            <div>
                                <div>{{ stats.gps.address }}</div>
                                <div class="time">{{ locationDate }}</div>
                            </div>
                        </div>


                        <div class="stats">
                            <h3>Details</h3>
                            <div>

                                <div class="row">
                                    <div class="name">License Plate</div>
                                    <div class="value" v-if="details.licensePlate">{{ details.licensePlate }}</div>
                                    <div class="value" v-else>-</div>
                                </div>

                                <div class="row">
                                    <div class="name">VIN</div>
                                    <div class="value" v-if="details.vinCode">{{ details.vinCode }}</div>
                                    <div class="value" v-else>-</div>
                                </div>

                            </div>
                        </div>


                        <div class="stats">
                            <h3>Diagnostics</h3>
                            <div>

                                <div class="row">
                                    <div class="name">Odometer</div>
                                    <div class="value" v-if="stats.odometer">{{ priceConverter(Number(stats.odometer.value / 1609.34).toFixed(0)) }}</div>
                                    <div class="value" v-else>-</div>
                                </div>

                                <div class="row">
                                    <div class="name">Fuel Level</div>
                                    <div class="value" v-if="stats.fuel">
                                        <div class="fuelLevelGrid">
                                            <div class="fuelLevelHolder">
                                                <div class="fuelPercent" :style="`width: ${stats.fuel.value}%;`"></div>
                                            </div>
                                            <div class="percentage">{{ stats.fuel.value }}%</div>
                                        </div>
                                    </div>
                                    <div class="value" v-else>-</div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <div class="map">
                <GoogleMaps  @loaded="mapLoaded" />
            </div>

        </div>

        <div style="display: none;" v-if="details">
            <VehicleMarker ref="Truck1" :truck="details" :stats="stats"/>
        </div>
    </div>
</template>

<script>
import { toRaw } from '@vue/reactivity'
import GoogleMaps from '../components/GoogleMaps.vue'
import VehicleMarker from '../components/GoogleMaps/VehicleMarker.vue'
import moment from 'moment'

    export default {
        components: {
            GoogleMaps,
            VehicleMarker
        },
        data() {
            return {
                map: null,
                details: null,
                stats: {
                    gps: null
                }
            }
        },
        computed: {
            truckId() {
                return this.$route.params.id;
            },
            locationDate() {
                if(this.stats && this.stats.gps) {
                    let date = moment(this.stats.gps.time);
                    return date.format('MMM D YYYY HH:mm');
                }
                return null;
            }
        },
        async mounted() {
            await this.getTruckDetails();
            this.getTrackingDetails();
        },
        watch: {
            "stats.gps": {
                deep: true,
                handler() {
                    this.showCurrentLocation();
                }
            }
        },
        methods: {
            async getTrackingDetails() {
                await this.ajax('GetTrackingDetails', {
                    url: `/myTrucks/tracking/${this.truckId}`,
                    method: 'GET'
                }, (err, body) => {
                    if(!err) {
                        this.stats = body;
                    }
                });
            },
            async getTruckDetails() {
                await this.ajax('GetTruckDetails', {
                    url: `/myTrucks/details/${this.truckId}`,
                    method: 'GET'
                }, (err, body) => {
                    if(!err) {
                        this.details = body;
                    }
                });
            },
            mapLoaded(map) {
                this.map = map;
            },
            showCurrentLocation() {

                if(!this.stats.gps) return;
                if(!this.stats.gps.lat) return;
                
                let marker = new this.map.google.maps.marker.AdvancedMarkerView({
                    map: toRaw(this.map.map),
                    position: { lat: this.stats.gps.lat, lng: this.stats.gps.lng },
                    content: this.$refs['Truck1'].$el,
                });
                
                const element = marker.element;

                ["focus", "pointerenter"].forEach((event) => {
                    element.addEventListener(event, () => {
                        marker.content.classList.add("shown");
                    });
                });

                ["blur", "pointerleave"].forEach((event) => {
                    element.addEventListener(event, () => {
                        marker.content.classList.remove("shown");
                    });
                });

                setTimeout(() => {
                    this.map.map.panTo(marker.position);
                    this.map.map.setZoom(20);
                }, 250);
                

            }
        }
    }
</script>


<style lang="scss" scoped>

.detailsItem {
    display: grid;
    column-gap: 20px;
    align-items: center;
    grid-template-columns: auto minmax(0, 1fr);
    .address {
        font-size: 13px;
    }
    .time {
        font-size: 12px;
        opacity: 0.75;
    }
}


.tracking-wrapper {
    position: absolute;
    top: 100px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 400px minmax(300px, 1fr);
}

.truckDetails {
    border-right: 1px solid $borderColor;
    background: $bgSecondary;
    overflow-y: auto;
    height: 100%;
    .truck-driver-details {
        min-height: 100%;
        padding: 20px;
        display: grid;
        row-gap: 20px;
        align-content: start;
        align-items: start;
        .details {
            display: grid;
            row-gap: 30px;
        }
    }
}

.map {
    background: #ccc;
    position: relative;
}


.fuelLevelGrid {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    column-gap: 10px;
    .percentage {
        font-size: 12px;
    }
}

.fuelLevelHolder {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $borderColor;
    .fuelPercent {
        height: 100%;
        background: $success;
        transition: ease 0.5s;
    }
}

.stats {
    display: grid;
    row-gap: 15px;
    .row {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $borderColor;
        .name {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
        }
        .value {
            font-size: 12px;
        }
    }
}
</style>